import React from 'react';
import RangeInput from './RangeInput';
import {Box} from '@mui/material';

const TimeInputSlider = (props: TimeInputSliderProps) => {
  const {minTime, maxTime} = props;

  const min = React.useRef(minTime / 1000);
  const max = React.useRef(maxTime / 1000);
  const [value, setValue] = React.useState(0);

  const handleChange = (value: number) => {
    setValue(value);
    return props.onChange(value * 1000);
  };

  React.useEffect(() => {
    setValue(props.value / 1000);
  }, [props.value]);

  return (
    <Box sx={{left: '24px', position: 'relative', width: 'calc(100% - 192px)'}}>
      <RangeInput min={min.current} max={max.current} onChange={handleChange} value={value} />
    </Box>
  );
};

export default TimeInputSlider;

type TimeInputSliderProps = {
  minTime: number;
  maxTime: number;
  value: number;
  onChange: (value: number) => void;
};
