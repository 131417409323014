import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const Checkbox = (props: CheckboxProps) => {
  const {checked, color = '#ff0060', onClick} = props;
  return (
    <React.Fragment>
      {checked ? (
        <CheckBoxIcon onClick={onClick} sx={{color, cursor: 'pointer'}} />
      ) : (
        <CheckBoxOutlineBlankIcon onClick={onClick} sx={{color, cursor: 'pointer'}} />
      )}
    </React.Fragment>
  );
};

export default Checkbox;

type CheckboxProps = {
  checked: boolean;
  color?: string;
  onClick: () => void;
};
