import React from 'react';

import './EventAnalysis.scss';

const EventAnalysis = (props) => {
  const {event, sub} = props;
  const url = process.env.REACT_APP_REPLAY + event.slug + (sub ? '/' + sub : '');

  if (event.status === 'finished' && event.analytic) {
    return (
      <div className="event-analysis">
        <a className="event-name" target="_blank" rel="noopener noreferrer" href={url}>
          Rerun
        </a>
      </div>
    );
  }

  return <></>;
};

export default EventAnalysis;
