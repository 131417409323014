import React from 'react';

import {CompetitorType, TrackPoint, useEventContext} from 'providers/EventProvider';
import {LatLngLiteral, LeafletMouseEvent} from 'leaflet';
import {toVector, vectorLen} from '../../common/TransformVectors';
import {Box, Button} from '@mui/material';
import {useLeafletRightClick} from './ReactLeafletRightClick';

const cal = (v1: LatLngLiteral, v2: LatLngLiteral) => {
  return vectorLen(toVector(v1, v2));
};

const MapContextMenu = () => {
  const [massStartFrom, setMassStartFrom] = React.useState<boolean>(false);

  const {players, updatePlayers, options, updateOptions, time: timeValue} = useEventContext();

  const leafletRightClick: LeafletMouseEvent | null = useLeafletRightClick();

  const onClickMassStart = () => {
    if (leafletRightClick) {
      setMassStartFrom(true);
      const latlng = leafletRightClick.latlng;
      const listPlayers: CompetitorType[] = Object.assign([], players);
      listPlayers.forEach((p) => {
        let track: TrackPoint[] = Object.assign([], p.track);
        track.sort((a, b) => cal(a, latlng) - cal(b, latlng));
        if (track[0]) {
          p.offsetTime = track[0].time - timeValue;
        }
      });
      const newOptions = Object.assign({}, options);
      newOptions.isMassStart = true;
      updatePlayers(listPlayers);
      updateOptions(newOptions);
    }
  };

  const onClickResetMassStart = () => {
    setMassStartFrom(false);
    const listPlayers: CompetitorType[] = Object.assign([], players);
    listPlayers.forEach((p) => {
      p.offsetTime = new Date(p.startTime).getTime();
    });
    const newOptions = Object.assign({}, options);
    newOptions.isMassStart = false;
    updatePlayers(listPlayers);
    updateOptions(newOptions);
  };

  return (
    <Box
      sx={{
        background: '#ffffff',
        padding: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        borderRadius: '4px'
      }}
    >
      {massStartFrom && (
        <Button sx={{textWrap: 'nowrap'}} onClick={onClickResetMassStart}>
          Reset mass-start
        </Button>
      )}
      <Button sx={{textWrap: 'nowrap'}} onClick={onClickMassStart}>
        Mass-start from here
      </Button>
    </Box>
  );
};

export default MapContextMenu;
