import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import './PlayerOptions.scss';
import {CompetitorType, useEventContext} from 'providers/EventProvider';
import {LatLngLiteral} from 'leaflet';

import {Box, Collapse, Stack} from '@mui/material';
import Checkbox from './Checkbox';

const toRadian = (degree: number) => {
  return (degree * Math.PI) / 180;
};

const getDistance = (origin: LatLngLiteral, destination: LatLngLiteral) => {
  // return distance in meters
  let lon1 = toRadian(origin.lng);
  let lat1 = toRadian(origin.lat);
  let lon2 = toRadian(destination.lng);
  let lat2 = toRadian(destination.lat);

  var deltaLat = lat2 - lat1;
  var deltaLon = lon2 - lon1;

  var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
  var c = 2 * Math.asin(Math.sqrt(a));
  var EARTH_RADIUS = 6371;
  return c * EARTH_RADIUS * 1000;
};

const addZero = (x: number) => {
  return x < 10 ? '0' + x : x;
};

const PlayerOptions = (props: PlayerOptionsProps) => {
  let {player, onChangeCenter} = props;

  const {players, updatePlayers, options, time, event} = useEventContext();

  const [expand, setExpand] = React.useState<boolean>(false);
  const [distance, setDistance] = useState(0);
  const [lastLive, setLastLive] = useState<string>();
  const [isLive, setIsLive] = useState(false);

  const onChangePlayerOptions = (player: CompetitorType) => {
    players.filter((p) => p.deviceName === player.deviceName).forEach((p) => (p.options = player.options));
    updatePlayers([...players]);
  };

  useEffect(() => {
    const startTime = new Date(player.startTime).getTime();
    const playerTime = time + (options.isMassStart ? player.offsetTime : options.startEventTime);
    const track = player.track.filter((d) => d.time >= startTime && d.time <= playerTime);
    let len = 0;
    if (track && track.length > 0) {
      for (let i = 1; i < track.length; i++) {
        len += getDistance(track[i - 1], track[i]);
      }
      if (playerTime - track[track.length - 1].time > event.delay * 500) {
        if (isLive) {
          const date = new Date(track[track.length - 1].time);
          const h = addZero(date.getHours());
          const m = addZero(date.getMinutes());
          setLastLive(`${h}:${m}`);
          setIsLive(false);
        }
      } else {
        setIsLive(true);
      }
      setDistance(len / 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.isLive, time]);

  return (
    <Box
      sx={{
        borderBottom: '1px solid rgba(0,0,0,0.4)',
        padding: '5px'
      }}
    >
      <Stack
        direction="row"
        sx={{
          paddingBottom: '10px',
          marginBottom: 0
        }}
      >
        <Checkbox
          checked={player.options.show}
          onClick={() => {
            player.options.show = !player.options.show;
            onChangePlayerOptions(player);
          }}
          color={player.color}
        />
        <Box>
          <span className="player_name" role="button" onClick={() => setExpand((expand) => !expand)}>
            {player.name}
          </span>
          {options.isLive && (isLive || lastLive) && (
            <span className={`player_live ${isLive ? 'live' : ''}`}>
              {/* <FontAwesomeIcon icon={faCircle} /> */}
              {isLive ? 'Live' : lastLive}
            </span>
          )}
          <span className="player_info">
            <span>{dayjs(player.startTime).format('HH:mm')}</span>
            <span>{distance.toFixed(2)} km</span>
          </span>
        </Box>
      </Stack>
      <Collapse in={expand}>
        <Box
          className="feature"
          onClick={() => {
            player.options.fullRoute = !player.options.fullRoute;
            onChangePlayerOptions(player);
          }}
          sx={player.options.fullRoute ? {color: player.color, fontWeight: 'bold'} : {}}
        >
          Full route: {player.options.fullRoute ? 'On' : 'Off'}
        </Box>
        <Box
          className="feature"
          onClick={() => {
            player.options.highlight = !player.options.highlight;
            onChangePlayerOptions(player);
          }}
          sx={player.options.highlight ? {color: player.color, fontWeight: 'bold'} : {}}
        >
          Highlight: {player.options.highlight ? 'On' : 'Off'}
        </Box>
        <Box className="feature" onClick={() => onChangeCenter(player)}>
          Center
        </Box>
      </Collapse>
    </Box>
  );
};

export default PlayerOptions;

type PlayerOptionsProps = {
  player: CompetitorType;
  onChangeCenter: (player: CompetitorType) => void;
};
