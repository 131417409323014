import {Box} from '@mui/material';
import React from 'react';
import {Range, getTrackBackground} from 'react-range';

const RangeInput = (props: RangeInputProps) => {
  const {max, min, onChange, value} = props;
  const values = React.useMemo(() => [value], [value]);

  const handleChange = (values: number[]) => {
    if (values.length > 0) {
      onChange(values[0]);
    }
  };
  return (
    <Range
      draggableTrack
      max={max}
      min={min}
      onChange={handleChange}
      values={values}
      renderThumb={({props, isDragged}) => (
        <Box
          {...props}
          style={{
            ...props.style,
            height: '30px',
            width: '30px',
            borderRadius: '50%',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA'
          }}
        >
          <Box
            style={{
              height: '16px',
              width: '5px',
              backgroundColor: isDragged ? '#ff0066' : '#CCC'
            }}
          />
        </Box>
      )}
      renderTrack={({props, children}) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: '40px',
            display: 'flex',
            width: '100%'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '5px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values: values,
                colors: ['#ff0066', '#ccc'],
                min: min,
                max: max
              }),
              alignSelf: 'center'
            }}
          >
            {children}
          </div>
        </div>
      )}
    />
  );
};

export default RangeInput;

type RangeInputProps = {
  max: number;
  min: number;
  onChange: (value: number) => void;
  value: number;
};
