import React from 'react';
import {useParams} from 'react-router';
import {gql, useQuery} from '@apollo/client';

import Loader from '../common/Loader';
import {EventContextProvider, EventType} from 'providers/EventProvider';
import Event from './Event';

const gqlQuery = gql`
  query GetSubEvent($slug: String!, $sub: String!) {
    liveEvent: getLiveEvent(slug: $slug, sub: $sub) {
      isLive
      slug
      sub
      name
      startDate
      endDate
      publicDate
      isOpen
      analytic
      delay
      location {
        lat
        lng
      }
      zoom
      status
      logos {
        id
        url
        name
        position
        width
      }
      competitors {
        id
        color
        name
        deviceName
        shortName
        startTime
      }
      maps {
        id
        name
        topLeft {
          lat
          lng
        }
        topRight {
          lat
          lng
        }
        bottomLeft {
          lat
          lng
        }
        height
        width
        url
        show
      }
      routes {
        id
        name
        color
        track {
          lat
          lng
        }
      }
      controls {
        id
        name
        type
        position {
          lat
          lng
        }
      }
    }
  }
`;

const EventPage = () => {
  const {slug, sub: subEvent} = useParams();

  const sub = subEvent ? subEvent : slug;

  const {error, loading, data} = useQuery<{liveEvent: EventType}>(gqlQuery, {
    variables: {
      slug,
      sub
    }
  });

  if (loading) return <Loader />;

  if (error || !data?.liveEvent) {
    return <>Something wrong</>;
  }

  return (
    <EventContextProvider
      value={{
        event: data.liveEvent,
        options: {
          showAllPlayers: true,
          isMassStart: false,
          isStop: !data.liveEvent.isLive,
          isLive: data.liveEvent.isLive,
          startEventTime: new Date(data.liveEvent.startDate).getTime(),
          tail: 60,
          speed: 1,
          center: data.liveEvent.location
        }
      }}
    >
      <Event />
    </EventContextProvider>
  );
};
export default EventPage;
