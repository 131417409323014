import React from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import dayjs from 'dayjs';

import './NotStartedEventMap.scss';

const NotStartedEventMap = (props) => {
  const {event} = props;

  return (
    <div>
      <MapContainer
        center={event.location}
        zoom={event.zoom}
        style={{
          width: '100%',
          zIndex: '0',
          cursor: 'default'
        }}
        maxZoom={22}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
      <div className="jumbotron">
        <h1>{event.name}</h1>
        <p className="lead">The GPS will be available after:</p>
        <h2>{dayjs(event.publicDate).format('YYYY-MM-DD HH:mm')}</h2>
        <hr className="my-4" />
      </div>
    </div>
  );
};

export default NotStartedEventMap;
