import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useEventContext} from 'providers/EventProvider';
import React from 'react';

let selectOptions = [
  {value: 1, label: '1'},
  {value: 3, label: '3'},
  {value: 5, label: '5'},
  {value: 7, label: '7'},
  {value: 10, label: '10'},
  {value: 20, label: '20'},
  {value: 50, label: '50'},
  {value: 100, label: '100'}
];

const SelectSpeed = () => {
  const {options, updateOptions} = useEventContext();

  const onChangeSpeed = (event: SelectChangeEvent<number>) => {
    updateOptions({
      ...options,
      speed: event.target.value as number
    });
  };

  return (
    <Select className="speed" value={options.speed} size="small" onChange={onChangeSpeed} sx={{width: '80px'}}>
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectSpeed;
