import React from 'react';
import SelectTail from '../SelectTail/SelectTail';
import PlayerOptions from './PlayerOptions';
import Loader from '../../common/Loader';

import './RightDrawer.scss';
import {CompetitorType, TrackPoint, useEventContext} from 'providers/EventProvider';
import {drawerWidth} from '../Event';

import {styled} from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Checkbox from './Checkbox';
import {Box, Stack} from '@mui/material';

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  backgroundColor: '#ff0060',
  color: 'white'
}));

const RightDrawer = (props: RightDrawerProps) => {
  const {loading, open, setOpen} = props;

  const {event, players, updatePlayers, time, options, updateOptions} = useEventContext();

  const onChangeAllPlayersShow = () => {
    const show = !options.showAllPlayers;
    players.forEach((p) => {
      p.options.show = show;
    });
    updatePlayers([...players]);
    options.showAllPlayers = show;
    updateOptions(options);
  };

  const onChangeCenter = (player: CompetitorType) => {
    let timeValue = time;
    if (options.isMassStart) {
      timeValue += player.offsetTime;
    } else {
      timeValue += options.startEventTime;
    }
    const poly: TrackPoint[] = [];
    player.track.forEach((d, i) => {
      if (d.time <= timeValue) {
        poly.push(d);
      }
    });
    if (poly.length > 0) {
      const point = poly[poly.length - 1];
      options.center = point;
      updateOptions(options);
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        }
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={() => setOpen(false)} sx={{color: 'white'}}>
          <ChevronRightIcon />
        </IconButton>
        {event.name}
      </DrawerHeader>
      <Stack
        direction="row"
        sx={{
          fontWeight: 'bold',
          borderBottom: '1px solid rgba(0,0,0,0.4)',
          padding: '5px',
          paddingBottom: '10px',
          marginBottom: 0,
          color: '#ff0060'
        }}
      >
        <Checkbox checked={options.showAllPlayers} onClick={onChangeAllPlayersShow} />
        <Box>All Competitors</Box>
      </Stack>
      <div className="competitors">
        {loading ? (
          <Loader />
        ) : (
          <>
            {players.map((p, i) => (
              <PlayerOptions key={i} player={p} onChangeCenter={onChangeCenter} />
            ))}
          </>
        )}
      </div>
      <div className="options">
        <SelectTail />
      </div>
    </Drawer>
  );
};

export default RightDrawer;

type RightDrawerProps = {
  loading: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};
