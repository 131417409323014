import React from 'react';
import socketIOClient, {Socket} from 'socket.io-client';
import {gql, useLazyQuery} from '@apollo/client';

import Loader from '../common/Loader';
import MapEvent from './MapEvent/MapEvent';
import Timer from './Timer/Timer';
import RightDrawer from './RightDrawer/RightDrawer';
import Clock from './Clock/Clock';
import NotStartedEventMap from './NotStartedEventMap';
import LivezoneLogo from './LivezoneLogo';
// import EventLogos from './EventLogos';
import EventAnalysis from './EventAnalysis';
import {CompetitorType, TrackPoint, useEventContext} from 'providers/EventProvider';

import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {toast} from 'react-toastify';

export const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
  open?: boolean;
}>(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative'
}));

const gqlCompetitorsQuery = gql`
  query GetCompetitorsByIds($ids: [Int!]!) {
    competitors: getCompetitorsByIds(ids: $ids) {
      deviceName
      trackCompress
    }
  }
`;

const GQL_LIVE_DEVICES_DATA = gql`
  query GetLiveDevices($names: [String!]!, $slug: String, $from: Date) {
    devices: getLiveDevices(names: $names, slug: $slug, from: $from) {
      name
      points
    }
  }
`;

const EventPage = () => {
  const {event, players, updatePlayers} = useEventContext();

  const [loading, setLoading] = React.useState(true);
  const [loadingCompetitors, setLoadingCompetitors] = React.useState(true);
  const [status, setStatus] = React.useState<string>();

  const [from, setFrom] = React.useState<number>();
  const [connected, setConnected] = React.useState(false);
  const socketRef = React.useRef<Socket>();

  const [open, setOpen] = React.useState(window.innerWidth >= 768);

  const [getCompetitorsData] = useLazyQuery<{competitors: Array<CompetitorType>}>(gqlCompetitorsQuery, {
    onCompleted: ({competitors}) => {
      const startEvent = new Date(event.startDate).getTime();
      competitors.forEach((element) => {
        const player = players.filter((p) => p.deviceName === element.deviceName)[0];
        player.track = element.trackCompress.map((p) => ({
          time: p[0] * 1000 + startEvent,
          lat: p[1],
          lng: p[2]
        }));
      });
      updatePlayers([...players]);
      setLoadingCompetitors(false);
    },
    onError: () => setLoadingCompetitors(false)
  });

  const [getLiveCompetitorsData] = useLazyQuery<{devices: Array<{name: string; points: Array<number[]>}>}>(GQL_LIVE_DEVICES_DATA, {
    onCompleted: ({devices}) => {
      const startEvent = new Date(event.startDate).getTime();
      players.forEach((p) => p.track.sort((a, b) => a.time - b.time));
      devices.forEach((element) => {
        const player = players.find((p) => p.deviceName === element.name);
        if (!player) return;
        player.track = [
          ...player.track,
          ...element.points.map((p) => ({
            time: p[0] * 1000 + startEvent,
            lat: p[1],
            lng: p[2]
          }))
        ];
      });
      players.forEach((p) => p.track.sort((a, b) => a.time - b.time));
      updatePlayers([...players]);
      setLoadingCompetitors(false);
      toast.dismiss();
    }
  });

  React.useEffect(() => {
    if (event.status === 'hidden' && !event.isOpen) {
      setStatus('upcoming');
      setLoading(false);
    } else {
      const dataPlayers: CompetitorType[] = JSON.parse(JSON.stringify(event.competitors));
      dataPlayers.forEach((p) => {
        p.track = [];
        p.offsetTime = new Date(p.startTime).getTime();
        p.options = {
          show: true,
          fullRoute: false,
          highlight: false
        };
      });
      const start = new Date(event.startDate).getTime();
      // const end = new Date(event.endDate).getTime();
      setFrom(start);

      updatePlayers(dataPlayers);
      setLoading(false);

      if (event.isLive) {
        socketRef.current = socketIOClient(process.env.REACT_APP_DOMAIN!, {
          path: '/live' + process.env.REACT_APP_API_SOCKET
        });
      } else {
        getCompetitorsData({
          variables: {
            ids: event.competitors.map((competitor) => competitor.id)
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  React.useEffect(() => {
    const addPointsToPlayer = (data: {track: TrackPoint[]; device: string}) => {
      const player = players.find((p) => p.deviceName === data.device);
      if (player) {
        data.track.forEach((d) => player.track.push(d));
        player.track.sort((a, b) => a.time - b.time);
      }
      updatePlayers([...players]);
    };

    const addBuffPointsToPlayer = (data: {track: TrackPoint[]; device: string}) => {
      const player = players.find((p) => p.deviceName === data.device);
      if (player) {
        data.track.forEach((d) => player.track.push(d));
        player.track.sort((a, b) => a.time - b.time);
      }
      updatePlayers([...players]);
    };

    if (socketRef.current) {
      socketRef.current.on('connect', () => setConnected(true));
      socketRef.current.on(event.slug + '_' + event.sub, addPointsToPlayer);
      socketRef.current.on('buff_' + event.slug + '_' + event.sub, addBuffPointsToPlayer);
      socketRef.current.on('announce_' + event.slug + '_' + event.sub, (data) => {
        if (data.type === 'refresh') {
          window.location.reload();
        }
      });
      socketRef.current.on('disconnect', () => {
        toast.info('Lost connection.', {
          autoClose: false,
          style: {backgroundColor: '#ff0066', color: '#ffffff'}
        });
        console.log('disconnect');
        setFrom(new Date().getTime());
        setConnected(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketRef, players]);

  React.useEffect(() => {
    if (connected) {
      console.log('connect');
      getLiveCompetitorsData({
        variables: {
          from,
          slug: event.slug,
          names: event.competitors.map((competitor) => competitor.deviceName)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );

  if (status === 'upcoming') {
    return <NotStartedEventMap event={event} />;
  }

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <IconButton
        color="primary"
        size="large"
        aria-label="open drawer"
        edge="end"
        onClick={() => setOpen(true)}
        sx={{position: 'absolute', background: '#ffffffaa', zIndex: 100, right: 0, margin: 0, ...(open && {display: 'none'})}}
      >
        <MenuIcon />
      </IconButton>
      <Main open={open} sx={{height: '100svh', padding: 0}}>
        <MapEvent />
        <Timer />
        <Clock />
        <EventAnalysis event={event} sub={event.sub} />
        <LivezoneLogo />
        {/* <EventLogos logos={event.logos} /> */}
      </Main>

      <RightDrawer loading={loadingCompetitors} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default EventPage;
