import React, {useState} from 'react';
import {gql, useMutation} from '@apollo/client';

import {Avatar, Box, Button, Card, FormControl, Grid, Typography} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import {FormContainer, TextFieldElement, TextFieldElementProps, ControllerProps, FieldValues, SubmitHandler} from 'react-hook-form-mui';

const LOGIN_QUERY = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      access_token
      refresh_token
      user {
        name
        role {
          name
        }
      }
    }
  }
`;

const TextInput = (props: TextFieldElementProps & {minLength?: number}) => {
  const {minLength, label, ...rest} = props;
  const validation = React.useMemo(() => {
    const value: ControllerProps<FieldValues>['rules'] = {};
    if (minLength) {
      value.minLength = {value: minLength, message: `${label} should be at least ${minLength} characters`};
    }

    return value;
  }, [minLength, label]);
  return <TextFieldElement margin="normal" label={label} validation={validation} {...rest} />;
};

type LoginPayloadType = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [error, setError] = React.useState<string>('');

  const [login] = useMutation(LOGIN_QUERY, {
    onCompleted: (data) => {
      localStorage.setItem('access_token', data.login.access_token);
      setIsSuccess(true);
    },
    onError: (error) => {
      if (error.message) {
        setError(error.message);
      } else {
        setError('Network error.');
      }
    }
  });

  const onSubmit: SubmitHandler<LoginPayloadType> = (payload) => {
    login({
      variables: payload
    });
  };

  if (isSuccess) {
    return <p>Success</p>;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} lg={6}>
        <Card>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h4">
              Sign in
            </Typography>
            <FormContainer onSuccess={onSubmit}>
              <TextInput required fullWidth name="email" label="Email" autoFocus />
              <TextInput required fullWidth type="password" name="password" label="Password" />
              <FormControl fullWidth sx={{mt: 3, mb: 2, alignItems: 'center'}}>
                <Button type="submit" variant="contained" sx={{width: 'fit-content'}}>
                  Sign In
                </Button>
              </FormControl>
              <Typography>{error}</Typography>
            </FormContainer>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
