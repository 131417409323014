import React from 'react';
import {useLeafletContext} from '@react-leaflet/core';
import Leaflet from 'leaflet';
import 'leaflet-imageoverlay-rotated';
import {LatLngLiteral} from 'leaflet';

const ImageOverlayRotated = (props: ImageOverlayRotatedProps) => {
  const context = useLeafletContext();
  const {image, topLeft, topRight, bottomLeft, opacity} = props;

  React.useEffect(() => {
    const container = context.layerContainer || context.map;
    const imageOverlay = Leaflet.imageOverlay.rotated(image, topLeft, topRight, bottomLeft, {
      opacity: opacity,
      interactive: false
    });
    container.addLayer(imageOverlay);
    return () => {
      container.removeLayer(imageOverlay);
    };
  }, [image, topLeft, topRight, bottomLeft, opacity, context]);
  return null;
};
export default ImageOverlayRotated;

type ImageOverlayRotatedProps = {
  image: string;
  topLeft: LatLngLiteral;
  topRight: LatLngLiteral;
  bottomLeft: LatLngLiteral;
  opacity: number;
};
