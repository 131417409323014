import React from 'react';
import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useEventContext} from 'providers/EventProvider';

// TODO: from beginning
const selectOptions = [
  {value: 0, label: '0'},
  {value: 10, label: '10 sec'},
  {value: 15, label: '15 sec'},
  {value: 20, label: '20 sec'},
  {value: 30, label: '30 sec'},
  {value: 45, label: '45 sec'},
  {value: 60, label: '1 min'},
  {value: 120, label: '2 min'},
  {value: 180, label: '3 min'},
  {value: 240, label: '4 min'},
  {value: 300, label: '5 min'},
  {value: 600, label: '10 min'},
  {value: 10000, label: 'from beginning'}
];

const SelectTail = () => {
  const {options, updateOptions} = useEventContext();

  const onChangeTail = (event: SelectChangeEvent<number>) => {
    updateOptions({
      ...options,
      tail: event.target.value as number
    });
  };

  return (
    <Select value={options.tail} size="small" onChange={onChangeTail} fullWidth>
      {selectOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectTail;
