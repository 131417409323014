import React from 'react';
import TimeInputSlider from './TimeInputSlider';
import SelectSpeed from './SelectSpeed';

import {OptionsType, useEventContext} from 'providers/EventProvider';
import {Button, Stack} from '@mui/material';

const Timer = () => {
  const {event, updateTime, options, updateOptions} = useEventContext();
  const eventStartTime = React.useMemo(() => new Date(event.startDate).getTime(), [event]);
  const eventEndTime = React.useMemo(() => new Date(event.endDate).getTime(), [event]);
  const minTime = 0;
  const maxTime = React.useMemo(() => eventEndTime - eventStartTime, [eventStartTime, eventEndTime]);

  const beginInterval = React.useRef<Date>();
  const startInterval = React.useRef<number>();
  const timer = React.useRef<NodeJS.Timer | null>();
  const count = React.useRef<number>(0);

  const [watchData, setWatchData] = React.useState<{clock: number}>({
    clock: minTime
  });
  const [tick, setTick] = React.useState<Date>();

  const stop = () => {
    timer.current && clearInterval(timer.current);
  };

  const update = (e: number) => {
    updateTime(e - event.delay * 1000);
  };

  const onChangeTime = (time: number) => {
    stop();
    update(time);
    if (!options.isStop) {
      start(time);
    } else {
      setWatchData((state) => ({...state, clock: time}));
    }
  };

  const start = (time: number) => {
    const interval = options.speed > 10 ? 1000 / options.speed : 100;
    beginInterval.current = new Date();
    startInterval.current = time;
    count.current = 0;
    stop();
    timer.current = setInterval(() => setTick(new Date()), interval);
    setWatchData((state) => ({...state, clock: time}));
  };

  const startTimer = () => {
    if (options.isStop) {
      options.isStop = false;
      updateOptions(options);
      start(watchData.clock);
    }
  };

  const stopTimer = () => {
    options.isStop = true;
    updateOptions(options);
    stop();
  };

  React.useEffect(() => {
    if (tick) {
      const now: Date = new Date();
      const time = startInterval.current! + options.speed * (now.getTime() - beginInterval.current!.getTime());
      setWatchData((state) => ({...state, clock: time}));
      if (options.speed > 10) {
        const fast = options.speed / 10;
        if (count.current % fast === 0) {
          update(time);
        }
        count.current++;
      } else {
        update(time);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick]);

  React.useEffect(() => {
    stop();
    if (!options.isStop) {
      start(watchData.clock);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.speed, options.isStop]);

  React.useEffect(() => {
    beginInterval.current = new Date();
    startInterval.current = minTime;
    count.current = 0;
    const now = new Date().getTime();
    const time = options.isLive ? now - eventStartTime : minTime;
    const newOptions = {...options};
    newOptions.speed = 10;
    if (options.isLive) {
      startInterval.current = time;
      newOptions.speed = 1;
    }
    updateOptions(newOptions);
    setWatchData({clock: minTime});

    return stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const newOptions: OptionsType = {...options};
    if (options.isLive) {
      beginInterval.current = new Date();
      const time = new Date().getTime() - eventStartTime;
      startInterval.current = time;
      count.current = 0;
      setWatchData((state) => ({...state, clock: time}));
      newOptions.speed = 1;
      newOptions.isStop = false;
    } else {
      setWatchData((state) => ({...state}));
      newOptions.speed = 10;
      newOptions.isStop = true;
    }
    updateOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.isLive]);

  if (options.isLive) {
    return <></>;
  } else {
    return (
      <Stack
        direction="row"
        alignItems="right"
        sx={{
          left: 0,
          position: 'absolute',
          bottom: 0,
          height: '40px',
          background: '#efefef',
          width: '100%'
        }}
      >
        {options.isStop && (
          <Button size="small" variant="contained" onClick={startTimer}>
            start
          </Button>
        )}
        {!options.isStop && (
          <Button size="small" variant="contained" onClick={stopTimer}>
            stop
          </Button>
        )}
        <SelectSpeed />
        <TimeInputSlider minTime={minTime} maxTime={maxTime} value={watchData.clock} onChange={onChangeTime} />
      </Stack>
    );
  }
};

export default Timer;
