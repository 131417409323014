import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import './App.css';
import EventPage from './components/EventPage/EventPage';
import {ApolloProvider} from '@apollo/react-hooks';
import {ApolloClient, createHttpLink, InMemoryCache, ApolloLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

import {ThemeProvider, createTheme} from '@mui/material';
import {cyan} from '@mui/material/colors';

import LoginPage from './components/Auth/LoginPage';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL
});

const authLink = setContext((_, {headers}) => {
  const accessToken = localStorage.getItem('access_token');

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  };
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false
  })
});

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#ff0066'
    },
    secondary: {
      main: cyan[500]
    }
  }
});

const App = () => {
  const routes = [
    {
      path: '/',
      element: <>Welcome</>
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/:slug',
      element: <EventPage />
    },
    {
      path: '/:slug/:sub',
      element: <EventPage />
    }
  ];
  const router = createBrowserRouter(routes);

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={defaultTheme}>
        <RouterProvider router={router} />
        <ToastContainer position="top-center" autoClose={3000} theme="light" />
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
