import React from 'react';
import dayjs from 'dayjs';

import {useEventContext} from 'providers/EventProvider';
import {Box, Button, Stack} from '@mui/material';

const Clock = () => {
  const {event, options, updateOptions, time} = useEventContext();

  const date = new Date(new Date(event.startDate).getTime() + time);

  const onMassStartHandle = () => {
    updateOptions({
      ...options,
      isMassStart: !options.isMassStart
    });
  };

  const onChangeLiveHandler = () => {
    const newOptions = options;
    if (!options.isLive) {
      newOptions.isMassStart = false;
    }
    newOptions.isLive = !options.isLive;
    updateOptions(newOptions);
  };

  return (
    <Stack
      sx={{
        position: 'absolute',
        right: '15px',
        bottom: '65px',
        background: '#efefef',
        width: '100px',
        textAlign: 'center',
        padding: '5px'
      }}
    >
      <Box sx={{fontSize: '22px'}}>{dayjs(date).format('HH:mm:ss')}</Box>
      {options.isLive ? (
        <Box sx={{textWrap: 'nowrap'}}>Live</Box>
      ) : (
        <Button
          size="small"
          sx={{textWrap: 'nowrap'}}
          variant="contained"
          color={options.isMassStart ? 'success' : 'primary'}
          onClick={onMassStartHandle}
        >
          Mass Start
        </Button>
      )}
      {event.status === 'live' ? (
        <Button size="small" sx={{textWrap: 'nowrap', marginTop: '5px'}} variant="contained" color="primary" onClick={onChangeLiveHandler}>
          {options.isLive ? 'Replay' : 'Live'}
        </Button>
      ) : (
        <Box sx={{textWrap: 'nowrap', marginTop: '5px'}}>Replay</Box>
      )}
    </Stack>
  );
};

export default Clock;
