export function vectorLen (p1) {
  return Math.sqrt((p1.lat * p1.lat) + (p1.lng * p1.lng))
}

export function toVector (p1, p2) {
  return {
    lng: p2.lng - p1.lng,
    lat: p2.lat - p1.lat
  }
}
