import React from 'react';
import MarkerPlayer from './MarkerPlayer';
import {useEventContext} from 'providers/EventProvider';

const Players = () => {
  const {players} = useEventContext();
  return (
    <React.Fragment>
      {players
        .filter((p) => p.track.length > 0)
        .map((p) => (
          <MarkerPlayer key={p.deviceName} player={p} />
        ))}
    </React.Fragment>
  );
};

export default Players;
