import React, {useEffect, useState} from 'react';
import {CircleMarker, Polyline, Tooltip} from 'react-leaflet';
import RGBcolor from 'color';
import {CompetitorType, TrackPoint, useEventContext} from 'providers/EventProvider';

const calculateStyle = (player: CompetitorType) => {
  const rgb = new RGBcolor(player.color);
  const shadowColor = rgb.red() * 0.299 + rgb.green() * 0.587 + rgb.blue() * 0.114 > 186 ? '#000000' : '#ffffff';
  return {
    color: player.color,
    textShadow: `-1px -1px 0 ${shadowColor}, 1px -1px 0 ${shadowColor}, -1px 1px 0 ${shadowColor}, 1px 1px 0 ${shadowColor}`,
    fontWeight: 'bold'
  };
};

const MarkerPlayer = (props: MarkerPlayerProps) => {
  const {event, options, time} = useEventContext();
  const eventStartDate = React.useMemo(() => new Date(event.startDate).getTime(), [event]);
  const {player} = props;
  const [polyLine, setPolyLine] = useState<TrackPoint[]>([]);
  const [marker, setMarker] = useState<TrackPoint>();
  const [opacity, setOpacity] = useState(0.5);

  const [style, setStyle] = useState(calculateStyle(player));

  useEffect(() => {
    const polyLine: TrackPoint[] = [];
    const nextTime = time + (options.isMassStart ? player.offsetTime : options.startEventTime);

    if (player.options.show) {
      player.track.forEach((d, i) => {
        if (d.time <= nextTime && (options.tail === 10000 || player.options.fullRoute || d.time >= nextTime - options.tail * 1000)) {
          polyLine.push(d);
        }
      });

      if (polyLine.length > 0) {
        const last = polyLine[polyLine.length - 1];
        const next = player.track.filter((d) => d.time > last.time);
        if (next && next[0]) {
          const start = last;
          const second = next[0];
          const k = (nextTime - start.time) / (second.time - start.time);
          const vLat = (second.lat - start.lat) * k;
          const vLng = (second.lng - start.lng) * k;
          const end = {
            lat: start.lat + vLat,
            lng: start.lng + vLng,
            time: start.time + k
          };
          polyLine.push(end);
        }
      } else {
        const last = player.track.filter((d) => d.time < nextTime);
        if (last && last[last.length - 1]) {
          polyLine.push(last[last.length - 1]);
        }
      }
    }

    setPolyLine(polyLine);
    if (polyLine.length) {
      const newMarker = polyLine[polyLine.length - 1];
      setMarker(newMarker);
      setOpacity(newMarker.time < eventStartDate + time - event.delay * 500 ? 0.5 : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, player.options.fullRoute, player.options.show, player.offsetTime, options.isMassStart, options.tail]);

  useEffect(() => {
    const newStyle = calculateStyle(player);
    if (player.options.highlight) {
      Object.assign(newStyle, {
        padding: '6px',
        background: 'white',
        border: '1px solid black',
        borderRadius: '5px',
        marginLeft: '-5px',
        zIndex: 'inherit'
      });
    }
    setStyle(newStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player.options.highlight]);

  if (polyLine.length === 0) return null;

  return (
    <React.Fragment>
      <Polyline color={player.color} positions={polyLine} />
      <CircleMarker
        center={marker!}
        pathOptions={{
          color: 'black',
          fillColor: player.color,
          opacity: opacity,
          fillOpacity: opacity,
          weight: 2
        }}
        radius={6}
      >
        <Tooltip className={'marker_text' + (player.options.highlight ? ' highlight_on' : '')} direction="right" offset={[6, 0]} permanent>
          <span style={{...style, opacity: opacity}}>{player.shortName}</span>
        </Tooltip>
      </CircleMarker>
    </React.Fragment>
  );
};

export default MarkerPlayer;

type MarkerPlayerProps = {
  player: CompetitorType;
};
