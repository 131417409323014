import React from 'react';
import {MapContainer, TileLayer, ScaleControl, LayersControl, Polyline, Marker, Popup, useMap, LayerGroup} from 'react-leaflet';
import ImageOverlayRotated from './ImageOverlayRotated';
import icons from '../Icons/MarkerIcons';

import {useEventContext} from 'providers/EventProvider';
import {LatLngLiteral} from 'leaflet';
import ReactLeafletRightClick, {LeafletRightClickProvider} from './ReactLeafletRightClick';
import Players from '../Players/Players';
import HideAttributeControl from './HideAttributeControl';
import MapContextMenu from './MapContextMenu';

const MapEvent = () => {
  const {event, options} = useEventContext();

  const location = React.useRef(event.location);

  const SetCenterComponent = ({center}: {center: LatLngLiteral}) => {
    const map = useMap();
    React.useEffect(() => {
      if (center !== location.current) {
        location.current = center;
        map.panTo(center);
      }
    }, [center, map]);
    return null;
  };

  return (
    <LeafletRightClickProvider>
      <MapContainer
        center={event.location}
        zoom={event.zoom}
        style={{
          width: '100%',
          zIndex: '0',
          cursor: 'default'
        }}
        maxZoom={22}
      >
        <HideAttributeControl />
        <SetCenterComponent center={options.center} />
        <TileLayer url="https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=8415e76f241042fb85d6e744d874ab6c" />
        <ScaleControl position="bottomleft" imperial={false} />
        <ReactLeafletRightClick customComponent={<MapContextMenu />} />
        <LayersControl position="topleft">
          {event.maps &&
            event.maps.map((m) => (
              <LayersControl.Overlay key={m.id} name={m.name} checked={true}>
                <LayerGroup>
                  <ImageOverlayRotated
                    topLeft={m.topLeft}
                    topRight={m.topRight}
                    bottomLeft={m.bottomLeft}
                    opacity={1}
                    image={process.env.REACT_APP_UPLOADS + m.url}
                  />
                </LayerGroup>
              </LayersControl.Overlay>
            ))}
          {event.routes &&
            event.routes.map((r) => (
              <LayersControl.Overlay key={r.id} name={r.name} checked={true}>
                <Polyline color={r.color} positions={r.track} opacity={0.7} />
              </LayersControl.Overlay>
            ))}
          {event.controls &&
            event.controls.map((m) => (
              <LayersControl.Overlay key={m.id} name={m.name} checked={true}>
                <Marker position={m.position} icon={icons[m.type]}>
                  <Popup>{m.name}</Popup>
                </Marker>
              </LayersControl.Overlay>
            ))}
        </LayersControl>
        <Players />
      </MapContainer>
    </LeafletRightClickProvider>
  );
};

export default MapEvent;
