import React from 'react';
import logo from '../../../livezone_logo.png';

import './LivezoneLogo.scss';

const LivezoneLogo = () => {
  return <img className="livezone-logo" src={logo} alt="LiveZone" />;
};

export default LivezoneLogo;
