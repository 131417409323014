import {LatLngLiteral} from 'leaflet';
import React from 'react';

export type TrackPoint = LatLngLiteral & {
  time: number;
};

export type CompetitorType = {
  id: number;
  name: string;
  color: string;
  shortName: string;
  deviceName: string;
  track: Array<TrackPoint>;
  trackCompress: Array<number[]>;

  startTime: string;

  offsetTime: number;

  options: {
    show: boolean;
    fullRoute: boolean;
    highlight: boolean;
  };
};

export type MapType = {
  id: number;
  name: string;
  topLeft: LatLngLiteral;
  topRight: LatLngLiteral;
  bottomLeft: LatLngLiteral;
  url: string;
};

export type RouteType = {
  id: number;
  name: string;
  color: string;
  track: TrackPoint[];
};

export type ControlType = {
  id: number;
  name: string;
  type: 'center' | 'default' | 'circle' | 'square' | 'triangle';
  position: LatLngLiteral;
};

export type EventType = {
  slug: string;
  sub: string;
  startDate: string;
  endDate: string;
  isLive: boolean;
  status: string;
  isOpen: boolean;
  name: string;
  location: LatLngLiteral;
  zoom: number;
  delay: number;

  competitors: Array<CompetitorType>;
  maps: Array<MapType>;
  routes: Array<RouteType>;
  controls: Array<ControlType>;
};

export type OptionsType = {
  showAllPlayers: boolean;
  isMassStart: boolean;
  startEventTime: number;
  tail: number;
  isLive: boolean;
  isStop: boolean;
  speed: number;
  center: LatLngLiteral;
};

export type EventContextType = {
  event: EventType;
  time: number;
  updateTime: (t: number) => void;
  options: OptionsType;
  updateOptions: (value: OptionsType) => void;
  players: Array<CompetitorType>;
  updatePlayers: (data: Array<CompetitorType>) => void;
};

export const EventContext = React.createContext<EventContextType>({
  event: {
    slug: '',
    sub: '',
    name: '',
    startDate: '',
    endDate: '',
    isLive: false,
    status: '',
    isOpen: false,
    location: {lat: 0, lng: 0},
    zoom: 1,
    delay: 30,

    competitors: [],
    maps: [],
    routes: [],
    controls: []
  },
  time: 0,
  updateTime: () => {},
  options: {
    showAllPlayers: true,
    isMassStart: false,
    isLive: false,
    isStop: false,
    startEventTime: 0,
    tail: 60,
    speed: 1,
    center: {lat: 0, lng: 0}
  },
  updateOptions: () => {},
  players: [],
  updatePlayers: () => {}
});

export const EventContextProvider = ({children, value}: EventContextProviderProps) => {
  const [time, setTime] = React.useState<number>(0);
  const [options, setOptions] = React.useState<OptionsType>(value.options);
  const [players, setPlayers] = React.useState<Array<CompetitorType>>([]);

  const updateOptions = React.useMemo(
    () => (data: OptionsType) => {
      setOptions((options) => ({...options, ...data}));
    },
    []
  );

  return (
    <EventContext.Provider value={{event: value.event, options, time, players, updateOptions, updateTime: setTime, updatePlayers: setPlayers}}>
      {children}
    </EventContext.Provider>
  );
};

export interface EventContextProviderProps {
  children: React.ReactNode;
  value: {event: EventType; options: OptionsType};
}

export const useEventContext = () => {
  const context = React.useContext<EventContextType>(EventContext);

  return context;
};
